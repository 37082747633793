
const FooterComp=()=>{
    return(
        <div>
            <div className="p-3 bg-primary text-center text-white">
                <p> CopyRight &copy; 2023 by Muhammad ilham</p>
            </div>
        </div>
    );
}

export default FooterComp;