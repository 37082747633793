import React from "react";
import "./dashboard.css";
import MenuDashboard from "../MenuDasboard";

const Dashboard = () => {
  return (
    <>
      <MenuDashboard />
    </>
  );
};

export default Dashboard;
